import React, { useState, useEffect } from "react";

const InfoPage = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("https://tce.fhco.ch/p/get_info_neu.php")
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  }, []);

  return (
    <div>
      {data.map((item) => (
        <div>
          {/* {`${alert(item)}`}; */}
          <div
            key={item.ID}
            dangerouslySetInnerHTML={{ __html: item.text }}
          ></div>

          {/* <div key={item.ID}>
            dangerouslySetInnerHTML={item.text}
             {item.text} 
          </div> */}
        </div>
      ))}
    </div>
  );
};
export default InfoPage;
