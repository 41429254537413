import React from "react";
import { Fragment, useState, useEffect } from "react";

import classes from "./RestrictionsData.module.css";
import Details from "./Details";

const RestrictionsData = () => {
  const url = "https://tce.fhco.ch/p/restriction_update_react.php";

  const mail = localStorage.getItem("tce_mail");

  const [mData, setMData] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  var nUrl = url + "/?mail=" + mail;
  useEffect(() => {
    setIsLoading(true);
    fetch(nUrl)
      .then((response) => response.json())
      .then((data) => {
        setMData(data);
        setIsLoading(false);
        // setName(data.name);
        // setDescription(data.description);
        // setQuantity(data.quantity);
      });
  }, [nUrl]);

  console.log(mData);

  const submitHandler = (event) => {
    event.preventDefault();

    if (selectedOption) {
      // call next JSX element or submit form
      setError("");
    } else {
      // show error message or prevent form submission
      setError("Anzeige auswählen");
      return;
    }

    setIsLoading(true);
    setMData(null);

    let url =
      "https://tce.fhco.ch/p/restriction_update_react.php?mail=" +
      mail +
      "&restriction=" +
      // restriction;
      selectedOption;

    fetch(url, {
      method: "GET",
    })
      .then((res) => {
        setIsLoading(false);
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            console.log(data);
            console.log(res);
            let errorMessage = "Authentication failed!";
            if (data && data.error && data.error.message) {
              errorMessage = data.error.message;
              setError(data.error.message);

              if (data.error.message === "TAN") {
                setError(true);
              }
              if (data.error.message === "FPW") {
                setError(true);
              }
              if (data.error.message === "CAI") {
                setError(true);
              }
            }
            throw new Error(errorMessage);
          });
        }
      })
      .then((data) => {
        console.log(data);
        setMData(data);
        // history.replace("/");
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  };

  return (
    <section className={classes.auth}>
      <h1>Datenanzeige ändern</h1>
      <form onSubmit={submitHandler}>
        <React.StrictMode>
          <Fragment>
            <div className={classes.control}>
              <div>
                <label htmlFor="select">
                  Wie sollen deine Daten in der Mitgliederliste angezeigt
                  werden?
                </label>
                {/* <select id="select" value={restriction} onChange={handleChange}> */}
                <select value={selectedOption} onChange={handleOptionChange}>
                  <option value=""></option>
                  <option value="9">
                    Alles anzeigen (Telefon, Mail, Adresse)
                  </option>
                  <option value="1">Telefon und Mail azeigen</option>
                  <option value="2">Adressdaten anzeigen</option>
                  <option value="0">Keine Daten anzeigen</option>
                </select>
              </div>
            </div>

            <div className={classes.actions}>
              {!isLoading && (
                <>
                  <button>Senden</button>
                </>
              )}
              {isLoading && <p>Sending request...</p>}

              <div className={classes.action}>
                {error ? <p>{error}</p> : null}
              </div>
              <div>{mData ? <Details data={mData} /> : null}</div>
            </div>
          </Fragment>
        </React.StrictMode>
      </form>
    </section>
  );
};
export default RestrictionsData;
