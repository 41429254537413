import classes from "./ForgetContent.module.css";
import { Fragment, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { redirect } from "react-router-dom";
//import AuthContext from "../../store/auth-context";
//import classes from "../Auth/AuthForm.module.css";
// import Modal from "./Modal";

const ForgetContent = () => {
  const navigate = useNavigate;
  const tanInputRef = useRef();

  //const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isMail, setIsMail] = useState(true);
  const [isConfirm, setIsConfirm] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(true);
  const [mail, setMail] = useState("");
  const handleMailChange = (event) => {
    setMail(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    // const enteredEmail = emailInputRef.current.value;
    const enteredTAN = tanInputRef.current.value;
    setIsLoading(true);
    let url = "https://tce.fhco.ch/p/forget_react.php";

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        email: mail,
        TAN: enteredTAN,
        forgetpassword: forgetPassword,
      }),
      // headers: {
      //   'Content-Type': 'application/json',
      // },
    })
      .then((res) => {
        setIsLoading(false);
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            console.log(data);
            console.log(res);
            let errorMessage = "nicht identifizierbar!";
            if (data && data.error && data.error.message) {
              errorMessage = data.error.message;
              setError(data.error.message);

              if (data.error.message === "TAN") {
                setError(true);
                setIsMail(false);
                setIsConfirm(true);
              }
              if (data.error.message === "FPW") {
                setError(true);
                setIsMail(false);
                setIsConfirm(true);
                setForgetPassword(false);
              }
            }
            throw new Error(errorMessage);
          });
        }
      })
      .then((data) => {
        console.log(data);

        // navigate("/", {replace: true});
      })
      .catch((err) => {
        //alert(err.message);
        // navigate("/", {replace: true});
      });
  };

  return (
    <section className={classes.auth}>
      <h1>Passwort vergessen</h1>
      <form onSubmit={submitHandler}>
        <Fragment>
          <div hidden={!isMail ? "hidden" : null}>
            <div className={classes.control}>
              <label htmlFor="email">Deine Email-Adresse</label>
              <input
                type="email"
                id="email"
                minLength="3"
                maxLength="60"
                value={mail}
                onChange={handleMailChange}
              />
            </div>
          </div>

          <div hidden={!isConfirm ? "hidden" : null}>
            <div className={classes.control}>
              <label htmlFor="password">TAN (siehe Mail)</label>
              <input
                type="password"
                id="password"
                minLength="4"
                maxLength="4"
                ref={tanInputRef}
              />
            </div>
          </div>

          <div className={classes.actions}>
            {!isLoading && (
              <>
                <button>Senden</button>
              </>
            )}
            {isLoading && <p>Sending request...</p>}

            <div className={classes.action}>
              {error ? <p>{error}</p> : null}
              {/* {error && <Modal />} */}
            </div>
          </div>
        </Fragment>
      </form>
    </section>
  );
};

export default ForgetContent;
