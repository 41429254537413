import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

// import AuthContext from "../../store/auth-context";
import classes from "./PasswortData.module.css";

const PasswortData = () => {
  const navigate = useNavigate();

  // const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  function handlePasswordChange(event) {
    setPassword(event.target.value);
  }
  function handlePassword2Change(event) {
    setPassword2(event.target.value);
  }

  const submitHandler = (event) => {
    event.preventDefault();

    setError("");
    console.log(password);
    console.log(password2);
    if (password === password2) {
      setError("");
    } else {
      setError("Die Passwörter sind nicht identisch");
      return;
    }

    setIsLoading(true);
    let url = "https://tce.fhco.ch/p/newpw_react.php";

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        email: localStorage.getItem("tce_mail"),
        password: password,
      }),
      // headers: {
      //   'Content-Type': 'application/json',
      // },
    })
      .then((res) => {
        setIsLoading(false);
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            console.log(data);
            console.log(res);

            let errorMessage = "Authentication failed!";
            if (data && data.error && data.error.message) {
              errorMessage = data.error.message;
              setError(data.error.message);

              if (data.error.message === "TAN") {
                setError(true);
              }
              if (data.error.message === "FPW") {
                setError(true);
              }
              if (data.error.message === "CAI") {
                setError(true);
              }
            }
            throw new Error(errorMessage);
          });
        }
      })
      .then((data) => {
        return navigate("/");
      })
      .catch((err) => {
        //alert(err.message);
      });
  };

  return (
    <section className={classes.auth}>
      <h1>Passwort ändern</h1>
      <form onSubmit={submitHandler}>
        <>
          <div>
            <div className={classes.control}>
              <label htmlFor="password">Dein neues Password</label>
              <input
                type="password"
                id="password"
                minLength="3"
                maxLength="30"
                required
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
          </div>

          <div>
            <div className={classes.control}>
              <label htmlFor="password">Password bestätigen</label>
              <input
                type="password"
                id="password2"
                minLength="3"
                maxLength="30"
                required
                value={password2}
                onChange={handlePassword2Change}
              />
            </div>
          </div>

          <div className={classes.actions}>
            {!isLoading && (
              <>
                <button>Senden</button>
              </>
            )}
            {isLoading && <p>Sending request...</p>}

            <div className={classes.action}>
              {error ? <p>{error}</p> : null}
            </div>
          </div>
        </>
      </form>
    </section>
  );
};

export default PasswortData;
