import { RouterProvider, createBrowserRouter } from "react-router-dom";

import ErrorPage from "./pages/Error";
import HomePage from "./pages/Home";
import RootLayout from "./pages/Root";
import Forget from "./pages/Forget";
import Mitglieder from "./pages/Mitglieder";
import UpdatePage from "./pages/UpdatePage";
import PasswortPage from "./pages/PasswortPage";
import RestrictionsPage from "./pages/RestrictionsPage";
import AdminPage from "./pages/AdminPage";
import AuthenticationPage, {
  action as authAction,
} from "./pages/Authentication";

import { action as logoutAction } from "./pages/Logout";
import { checkAuthLoader, tokenLoader } from "./util/auth";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    id: "root",
    loader: tokenLoader,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: "auth",
        element: <AuthenticationPage />,
        action: authAction,
      },
      {
        path: "forget",
        element: <Forget />,
        action: Forget,
        // loader: checkAuthLoader,
      },
      {
        path: "mitglieder",
        element: <Mitglieder />,
        action: Mitglieder,
        // loader: checkAuthLoader,
      },
      {
        path: "update",
        element: <UpdatePage />,
        action: UpdatePage,
        // loader: checkAuthLoader,
      },
      {
        path: "restrictions",
        element: <RestrictionsPage />,
        action: RestrictionsPage,
        // loader: checkAuthLoader,
      },
      {
        path: "passwort",
        element: <PasswortPage />,
        action: PasswortPage,
        // loader: checkAuthLoader,
      },
      {
        path: "logout",
        action: logoutAction,
      },
      {
        path: "admin",
        element: <AdminPage />,
        action: AdminPage,
        // loader: checkAuthLoader,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
export { authAction };
