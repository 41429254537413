import { Form, NavLink, useRouteLoaderData } from "react-router-dom";

import classes from "./MainNavigation.module.css";

function MainNavigation() {
  const token = useRouteLoaderData("root");
  const admin = useRouteLoaderData("admin");
  const staff = useRouteLoaderData("root");

  // const routeLoaderData = useRouteLoaderData("root");
  // const { data: routeData } = routeLoaderData || {};
  // const { token, admin } = routeData || {};

  console.log(token);
  console.log(admin);
  console.log(staff);

  return (
    <header className={classes.header}>
      <nav className={classes.navbar}>
        <ul className={classes.links}>
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
              end
            >
              Home
            </NavLink>
          </li>

          {!token && (
            <li>
              <NavLink
                to="/auth?mode=login"
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
              >
                Anmelden
              </NavLink>
            </li>
          )}
          {!token && (
            <li>
              <NavLink
                to="/forget"
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
              >
                Passwort vergessen
              </NavLink>
            </li>
          )}
          {token && (
            <>
              <li>
                <NavLink
                  to="/mitglieder"
                  className={({ isActive }) =>
                    isActive ? classes.active : undefined
                  }
                  end
                >
                  Mitglieder-<br></br>Liste
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/update"
                  className={({ isActive }) =>
                    isActive ? classes.active : undefined
                  }
                  end
                >
                  Mitglieder-<br></br>Mutationen
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/passwort"
                  className={({ isActive }) =>
                    isActive ? classes.active : undefined
                  }
                  end
                >
                  Passwort<br></br>ändern
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/restrictions"
                  className={({ isActive }) =>
                    isActive ? classes.active : undefined
                  }
                  end
                >
                  Datenanzeige<br></br>ändern
                </NavLink>
              </li>
              <li>
                <Form action="/logout" method="post">
                  <button>Logout</button>
                </Form>
              </li>
            </>
          )}
          {admin === true && (
            <li>
              <NavLink
                to="/admin"
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
                end
              >
                Admin
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
}

export default MainNavigation;
