import MitgliederData from '../components/Mitglieder/MitgliederData';

function MitgliederPage() {
  return (
    <MitgliederData>

    </MitgliederData>
  );
}

export default MitgliederPage;