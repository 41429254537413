import React, { Component } from "react";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "bootstrap/dist/css/bootstrap.min.css"; // neu

export class UpdateFunc extends Component {
  componentDidMount() {
    console.log(this.props.data);
    //initialize datatable
    var table = $("#example").DataTable({
      retrieve: true,
      // paging: false,
      language: {
        url: "https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/German.json",
      },
      data: this.props.data,
      order: [[7, "asc"]],

      columns: [
        {
          className: "details-control",
          orderable: false,
          data: null,
          defaultContent: "",
        },
        { data: "Anrede" },
        { data: "Vorname" },
        { data: "Nachname" },
        { data: "Status" },
        { data: "PLZ" },
        { data: "Ort" },
        {
          data: "Eintritt",
          // render: function (data, type, row) {
          //   if (data) {
          //     var dateSplit = data.split("-");
          //     return type === "display" || type === "filter"
          //       ? dateSplit[2] + "." + dateSplit[1] + "." + dateSplit[0]
          //       : data;
          //   }
          // },
        },
        {
          data: "Austritt",
          // render: function (data, type, row) {
          // if (data) {
          //   var dateSplit = data.split("-");
          //   return type === "display" || type === "filter"
          //     ? dateSplit[2] + "." + dateSplit[1] + "." + dateSplit[0]
          //     : data;
          // }
          // },
        },
        { data: "ID" },
      ],
      columnDefs: [
        {
          className: "dtr-control",
          title: "Details",
          orderable: false,
          targets: 0,
        },

        {
          title: "Anrede",
          targets: 1,
        },
        {
          title: "Vorname",
          targets: 2,
        },
        {
          title: "Nachname",
          targets: 3,
        },
        {
          title: "Status",
          targets: 4,
        },
        {
          title: "PLZ",
          targets: 5,
        },
        {
          title: "Ort",
          targets: 6,
        },
        {
          // DataTable.render.datetime()

          title: "Eintritt",
          targets: 7,
        },
        {
          title: "Austritt",
          targets: 8,
        },
        {
          title: "ID",
          targets: 9,
          visible: false,
        },
      ],
    });

    //........................
    /* Formatting function for row details - modify as you need */
    function format(d) {
      // `d` is the original data object for the row

      var xpdf = "";
      var hc = "'";

      var url = "https://tce.fhco.ch/p/get_mitglieder_details_react.php";
      var xID = "";
      var xdata = d.ID;
      //console.log(xdata);
      var xMitglied = "";
      if (d.ID > "") {
        $.ajax({
          url: url,
          data: { action: xdata },
          async: false,
          type: "POST",
          //contentType: 'application/json',
          success: function (data) {
            // console.log(data);
            console.log(data.Nachname);

            if (data.Vorname) {
              xMitglied +=
                "<tr><td>Vorname</td>" + "<td>" + data.Vorname + "</td></tr>";
              xMitglied +=
                "<tr><td>Nachname</td>" + "<td>" + data.Nachname + "</td></tr>";
              xMitglied +=
                "<tr><td>Adresse</td>" + "<td>" + data.Adresse + "</td></tr>";
              xMitglied +=
                "<tr><td>Ort</td>" +
                "<td>" +
                data.PLZ +
                " " +
                data.ORT +
                "</td></tr>";
            }
            if (data.Telefon) {
              xMitglied +=
                "<tr><td>Telefon</td>" + "<td>" + data.Telefon + "</td></tr>";
            }
            if (data.Mobile) {
              xMitglied +=
                "<tr><td>Mobile</td>" + "<td>" + data.Mobile + "</td></tr>";
            }
            if (data.Mail) {
              xMitglied +=
                "<tr><td>Mail</td>" + "<td>" + data.Mail + "</td></tr>";
            }
          },
          error: function (request, error) {
            // This callback function will trigger on unsuccessful action
            alert("Network error has occurred please try again!");
          },
        });
      }

      var x =
        '<h5><table style="width:100%">' +
        '<col style="width:30%">' +
        '<col style="width:60%">' +
        '<col style="width:10%">' +
        xMitglied +
        "</table></h5>";
      return x;
    }

    var isChildRowOpen = false; // aus ChatGPT

    $("#example tbody").on("click", "td.details-control", function () {
      var tr = $(this).closest("tr");
      var row = table.row(tr);

      if (isChildRowOpen) {
        // Close the child row if it is already open
        row.child.hide();
        tr.removeClass("shown");
        isChildRowOpen = false;
      } else {
        // Display the child row and set isChildRowOpen to true
        row.child(format(row.data())).show();
        tr.addClass("shown");
        isChildRowOpen = true;
      }
    });

    //........................
  }

  //   columnDefs: [
  //     {
  //         searchPanes: {
  //             header: 'neue Überschrift'
  //         },
  //         targets: [3]
  //     }
  // ]

  render() {
    //Datatable HTML
    return (
      <div className="MainDiv">
        <div className="jumbotron text-center">{/* <h3>Lieder</h3> */}</div>

        <div className="container">
          <div>
            <table
              id="example"
              className="display"
              width="100%"
              ref={(el) => (this.el = el)}
            ></table>
          </div>
        </div>
      </div>
    );
  }
}
export default UpdateFunc;
