import InfoPage from "./InfoPage";
import classes from "./StartingPageContent.module.css";

const StartingPageContent = () => {
  // const [InfoData, setInfoData] = useState();

  // function getInfo() {
  //   let url = "https://tce.fhco.ch/p/get_info.php";
  //   debugger;
  //   fetch(url, {
  //     method: "GET",
  //   })
  //     .then((res) => {
  //       console.log("in 1 then");
  //       if (res.ok) {
  //         console.log(res.json);
  //         return res.json();
  //       }
  //     })
  //     .then((data) => {
  //       console.log("in 2 then");
  //       console.log(data);
  //       setInfoData(data);
  //     });
  //   console.log(InfoData);
  //   return InfoData;
  // }

  return (
    <section className={classes.starting}>
      {/* Diese Homepage ermöglicht einen gesichterten Zugang zur Mitgliederliste
      des Tennisclubs Engematt. <br></br>
      Bevor diese angezeigt wird, musst du deine eMail-Adresse, dein Passwort
      und 4-stellige TAN-Nummer eingegeben. <br></br>
      <br></br>
      Falls du noch kein Konto hast - beim allerersten Logon - musst du auch
      dieses eröffnen. Natürlich wirst du vom System dahin geführt.<br></br>
      <br></br>
      <h3>
        Jedes Mitglied hat die Möglichkeit zu entscheiden (bei der
        Kontoeröffnung oder später), welche Daten (alle Angaben, nur Mail und
        Telefon, nur Adresse oder gar nichts) angezeigt werden sollen.
      </h3> */}
      {/* {`${getInfo(InfoData)}`} */}
      <InfoPage></InfoPage>
    </section>
  );
};

export default StartingPageContent;
