import React, { Component } from "react";

import "./MitgliederData.css";

//Bootstrap and jQuery libraries
// import "bootstrap/dist/css/bootstrap.min.css";
// import "jquery/dist/jquery.min.js";

//Datatable Modules
// import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
// import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";

import "bootstrap/dist/css/bootstrap.min.css"; // neu

import "datatables.net-searchpanes";
import "./searchPanes.css";
import "datatables.net-select";
import "./select.css";
// import "datatables.net-buttons";   // used for pdf, Excel, CSV only

// import "datatables.net/js/jquery.dataTables.min.js";

export class Tbl1Func extends Component {
  componentDidMount() {
    //initialize datatable

    console.log(this.props.data);

    var table = $("#example").DataTable({
      retrieve: true,
      // paging: false,

      dom: "BlfrtipP",

      language: {
        url: "//cdn.datatables.net/plug-ins/1.13.1/i18n/de-DE.json",
        // url: "https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/German.json",
      },
      data: this.props.data,
      order: [[3, "asc"]],
      //buttons: ["searchPanes"],
      //dom: "BfrtipP",

      columns: [
        {
          className: "details-control",
          orderable: false,
          data: null,
          defaultContent: "",
        },
        { data: "Anrede" },
        { data: "Vorname" },
        { data: "Nachname" },
        { data: "Status" },
        { data: "PLZ" },
        { data: "Ort" },
        { data: "ID" },
      ],

      columnDefs: [
        {
          className: "dtr-control",
          title: "Details",
          orderable: false,
          targets: 0,
        },
        {
          title: "Anrede",
          targets: 1,
        },
        {
          searchPanes: {
            show: false,
          },
          title: "Vorname",
          targets: 2,
        },
        {
          searchPanes: {
            show: false,
          },
          title: "Nachname",
          targets: 3,
        },
        {
          title: "Status",
          targets: 4,
        },
        {
          searchPanes: {
            show: false,
          },
          title: "PLZ",
          targets: 5,
        },
        {
          title: "Ort",
          targets: 6,
        },
        {
          // searchPanes: {
          //   show: true,
          // },
          title: "ID",
          targets: 7,
          visible: false,
        },
      ],
    });

    //........................
    /* Formatting function for row details - modify as you need */
    function format(d) {
      // `d` is the original data object for the row

      var url = "https://tce.fhco.ch/p/get_mitglieder_details_react.php";
      var usermail = localStorage.getItem("tce_mail");
      console.log(usermail);
      var xdata = d.ID + ";" + usermail;
      console.log(xdata);
      //console.log(xdata);
      var xMitglied = "";
      if (d.ID > "") {
        $.ajax({
          url: url,
          data: { action: xdata },
          async: false,
          type: "POST",
          // contentType: "application/json",
          success: function (data) {
            //console.log(data);
            //console.log(data.Nachname);
            if (data.Vorname) {
              xMitglied +=
                "<tr><td>Vorname</td>" + "<td>" + data.Vorname + "</td></tr>";
            }
            if (data.Nachname) {
              xMitglied +=
                "<tr><td>Nachname</td>" + "<td>" + data.Nachname + "</td></tr>";
            }
            if (data.Adresse) {
              xMitglied +=
                "<tr><td>Adresse</td>" + "<td>" + data.Adresse + "</td></tr>";
            }
            if (data.PLZ) {
              xMitglied +=
                "<tr><td>Ort</td>" +
                "<td>" +
                data.PLZ +
                " " +
                data.ORT +
                "</td></tr>";
            }
            if (data.Mail) {
              xMitglied +=
                "<tr><td>Mail</td>" + "<td>" + data.Mail + "</td></tr>";
            }
            if (data.Telefon) {
              xMitglied +=
                "<tr><td>Telefon</td>" + "<td>" + data.Telefon + "</td></tr>";
            }
            if (data.Mobile) {
              xMitglied +=
                "<tr><td>Mobile</td>" + "<td>" + data.Mobile + "</td></tr>";
            }
          },
          error: function (request, error) {
            // This callback function will trigger on unsuccessful action
            console.log(request);
            alert("Network error has occurred please try again!");
          },
        });
      }

      var x =
        '<h5><table style="width:100%">' +
        '<col style="width:30%">' +
        '<col style="width:60%">' +
        '<col style="width:10%">' +
        xMitglied +
        "</table></h5>";
      // console.log(x);
      return x;
    }

    var isChildRowOpen = false;

    $("#example tbody").on("click", "td.details-control", function () {
      var tr = $(this).closest("tr");
      var row = table.row(tr);

      if (isChildRowOpen) {
        // Close the child row if it is already open
        row.child.hide();
        tr.removeClass("shown");
        isChildRowOpen = false;
      } else {
        // Display the child row and set isChildRowOpen to true
        row.child(format(row.data())).show();
        tr.addClass("shown");
        isChildRowOpen = true;
      }
    });

    // Close the child row if the user clicks the close button within it
    $("#example tbody").on("click", ".close-child-row", function () {
      var tr = $(this).closest("tr");
      var parentTr = tr.prev(".parent-row");
      var parentRow = table.row(parentTr);

      parentRow.child.hide();
      parentTr.removeClass("shown");
      isChildRowOpen = false;
    });

    //........................
  }

  //   columnDefs: [
  //     {
  //         searchPanes: {
  //             header: 'neue Überschrift'
  //         },
  //         targets: [3]
  //     }
  // ]

  render() {
    //Datatable HTML
    return (
      <div className="MainDiv">
        <div className="jumbotron text-center">{/* <h3>Mitglieder</h3> */}</div>

        <div className="container">
          <div>
            <table
              id="example"
              className="display"
              width="100%"
              ref={(el) => (this.el = el)}
            ></table>
          </div>
        </div>
      </div>
    );
  }
}
export default Tbl1Func;
