import {
  // vor Anpassung Logon und SignUp separat
  Form,
  Link,
  useSearchParams,
  useActionData,
  useNavigation,
} from "react-router-dom";
import React, { useState, useEffect } from "react";

import classes from "./AuthForm.module.css";

function AuthForm() {
  const data = useActionData();
  const navigation = useNavigation();

  const [searchParams] = useSearchParams();
  const isLogin = searchParams.get("mode") === "login";
  const isSubmitting = navigation.state === "submitting";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [tan, setTan] = useState("");
  const [restriction, setRestriction] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [is2fa, setIs2fa] = useState(false);

  const handleChangeRestriction = (event) => {
    setRestriction(event.target.value);
  };

  function handleTan(event) {
    setTan(event.target.value);
  }

  function handleEmail(event) {
    setEmail(event.target.value);
  }

  function handlePassword(event) {
    setPassword(event.target.value);
    setPasswordsMatch(event.target.value === password2);
  }

  function handlePassword2(event) {
    setPassword2(event.target.value);
    setPasswordsMatch(event.target.value === password);
  }

  useEffect(() => {
    if (data && data.error.message && data.error.message.includes("TAN")) {
      setIs2fa(true);
    }
  }, [data]);

  return (
    <>
      <section className={classes.auth}>
        <Form method="post" className={classes.form}>
          <h1>{isLogin ? "Anmelden" : "Konto erstellen"}</h1>
          {/* {data && data.error.message && data.error.message && (
            <p>{data.error.message}</p>
          )} */}
          {data && data.errors && (
            <ul>
              {Object.values(data.errors).map((err) => (
                <li key={err}>{err}</li>
              ))}
            </ul>
          )}
          {/* {data && data.error.message && <p>{data.error.message}</p>} */}
          {
            <>
              {!is2fa && (
                <>
                  <div className={classes.control}>
                    <label htmlFor="email">Email</label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      value={email}
                      required
                      onChange={handleEmail}
                    />
                  </div>
                  <div className={classes.control}>
                    <label htmlFor="image">Password</label>
                    <input
                      id="password"
                      type="password"
                      name="password"
                      value={password}
                      required
                      onChange={handlePassword}
                    />
                  </div>
                </>
              )}

              {!isLogin && (
                <>
                  {!is2fa && (
                    <>
                      <div className={classes.control}></div>
                      <label htmlFor="password">Password bestätigen </label>
                      <div className={classes.control}></div>
                      <input
                        type="password"
                        id="password2"
                        value={password2}
                        required
                        onChange={handlePassword2}
                      />
                      {passwordsMatch ? null : (
                        <p>die Passwörter sind nicht identisch</p>
                      )}
                      <div className={classes.control}></div>
                      <label htmlFor="select">
                        Wie sollen deine Daten in der Mitgliederliste angezeigt
                        werden?
                      </label>
                      <div className={classes.control}></div>
                      <select
                        id="select"
                        onChange={handleChangeRestriction}
                        name="restriction"
                      >
                        <option value="">Wähle deine Variante </option>
                        <option value="9">
                          Alles anzeigen (Telefon, Mail, Adresse)
                        </option>
                        <option value="1">Telefon und Mail azeigen </option>
                        <option value="2">Adressdaten anzeigen </option>
                        <option value="0">Keine Daten anzeigen </option>
                      </select>
                    </>
                  )}
                </>
              )}
            </>
          }

          {/* {data && data.error.message === "TAN" && ( */}
          {is2fa && (
            <>
              <div className={classes.control}>
                {/* <label htmlFor="email">Email</label> */}
                <input
                  hidden
                  id="email"
                  type="email"
                  name="email"
                  value={email}
                  required
                  onChange={handleEmail}
                />
              </div>
              <div className={classes.control}>
                {/* <label htmlFor="image">Password</label> */}
                <input
                  hidden
                  id="password"
                  type="password"
                  name="password"
                  value={password}
                  required
                  onChange={handlePassword}
                />
              </div>
              <div className={classes.control}>
                {/* <label htmlFor="image">Berechtigungscode (siehe Mail)</label> */}
                <input
                  id="tan"
                  type="password"
                  name="tan"
                  value={tan}
                  onChange={handleTan}
                  minLength="4"
                  maxLength="4"
                />
              </div>
            </>
          )}

          <div className={classes.actions}>
            <Link to={`?mode=${isLogin ? "signup" : "login"}`}>
              {isLogin ? "Konto eröffnen" : "Anmelden"}
            </Link>
            <button disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Senden"}
            </button>
          </div>
        </Form>
      </section>
    </>
  );
}

export default AuthForm;
