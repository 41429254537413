import ForgetContent from '../components/ForgetContent';

function forgetPage() {
  return (
    <ForgetContent title="Welcome!">

    </ForgetContent>
  );
}

export default forgetPage;