const Details = (props) => {
  return (
    <>
      <br></br>
      <p>So sehen andere Teilnehmer deine Daten</p>
      <table>
        <thead>
          {props.data.email ? (
            <tr align="left">
              <td>eMail</td>
              <td>{props.data.email}</td>
            </tr>
          ) : null}
          {props.data.vorname ? (
            <tr align="left">
              <td>Vorname</td>
              <td>{props.data.vorname}</td>
            </tr>
          ) : null}
          {props.data.nachname ? (
            <tr align="left">
              <td>Nachname</td>
              <td>{props.data.nachname}</td>
            </tr>
          ) : null}
          {props.data.strasse ? (
            <tr align="left">
              <td>Strasse</td>
              <td>{props.data.strasse}</td>
            </tr>
          ) : null}
          {props.data.plz ? (
            <tr align="left">
              <td>PLZ Ort</td>
              <td>
                {props.data.plz} {props.data.ort}
              </td>
            </tr>
          ) : null}
          {props.data.mobile ? (
            <tr align="left">
              <td>Tel Mobil</td>
              <td>{props.data.mobile}</td>
            </tr>
          ) : null}
          {props.data.telp ? (
            <tr align="left">
              <td>Tel Privat</td>
              <td>{props.data.strasse}</td>
            </tr>
          ) : null}
          {props.data.eintritt ? (
            <tr align="left">
              <td>Eintritt</td>
              <td>{props.data.eintritt}</td>
            </tr>
          ) : null}
          {props.data.austritt ? (
            <tr align="left">
              <td>Austritt</td>
              <td>{props.data.austritt}</td>
            </tr>
          ) : null}
        </thead>
      </table>

      {/* <h1>Hello, {props.data.nachname}</h1>; */}
    </>
  );
};

export default Details;
