import { json, redirect } from "react-router-dom";

import AuthForm from "../components/AuthForm";

function AuthenticationPage() {
  return <AuthForm />;
}

export default AuthenticationPage;

export async function action({ request }) {
  const searchParams = new URL(request.url).searchParams;
  const mode = searchParams.get("mode") || "login";
  let action = true;
  if (mode === "signup") {
    action = false;
  }

  if (mode !== "login" && mode !== "signup") {
    throw json({ message: "Unsupported mode." }, { status: 422 });
  }

  const data = await request.formData();
  const authData = {
    email: data.get("email"),
    password: data.get("password"),
    action: action,
    TAN: data.get("tan"),
    question: data.get("question"),
    answer: data.get("answer"),
    restriction: data.get("restriction"),
  };

  // const response = await fetch('http://localhost:8080/' + mode, {
  const response = await fetch(
    "https://tce.fhco.ch/p/logon_react_neu.php/" + mode,
    {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify(authData),
    }
  );

  if (response.status === 400) {
    //  TAN: force TAN input CAI: user already               if (data.error.message === "TAN") {
    // setIs2fa(true);
    return response;
  }
  if (
    response.status === 422 ||
    response.status === 401 ||
    response.status === 400
  ) {
    return response;
  }

  if (!response.ok) {
    throw json({ message: "Could not authenticate user." }, { status: 500 });
  }

  const resData = await response.json();
  const token = resData.idToken;
  const ipdec = resData.ipdec;
  const admin = resData.admin;

  // const { idToken: token, admin } = await response.json();
  // console.log(token);
  // console.log(admin);
  // return { token, admin };

  // const { token, admin, staff, ipdec } = await response.json();

  const expiration = new Date();
  expiration.setHours(expiration.getHours() + 1);

  localStorage.setItem("tce_token", token);
  localStorage.setItem("tce_expiration", expiration.toISOString());
  localStorage.setItem("tce_mail", authData.email);
  localStorage.setItem("tce_ip_address", ipdec);

  // return { token, admin };

  return redirect("/");
}
