import React, { useState, useEffect } from "react";
//import use1Fetch from "../use1Fetch";
import { Tbl1Func } from "./Tbl1Func";

//const [lieder] = use1Fetch("https://cmgt.fhco.ch//get_lieder_react.php");

function MitgliederData() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const url = "https://tce.fhco.ch/p/get_mitglieder_react.php";
  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        //console.log([data]);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  //return [data];

  if (isLoading) {
    return (
      <section>
        <h2>
          <p>Loading...</p>
        </h2>
      </section>
    );
  }
  if (!isLoading) return <Tbl1Func data={data}></Tbl1Func>;
}

export default MitgliederData;
